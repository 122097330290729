import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import api from '../../api';
import './userList.css';

export default function UserList() {
    const [rows, setRows] = useState([]);

    useEffect(() => {
        api.getUsers().then(res => {
            setRows(res.data);
        });
    }, []);

    const columns = [
        // { field: '_id', headerName: 'ID', width: 100 },
        {
            field: 'name', headerName: 'User', width: 200, renderCell: (params) => {
                return (
                    <div className="userListUser">
                        <img src={params.row.avatar} alt="" className="userListImg" />
                        {params.row.name}
                    </div>
                );
            }
        },
        { field: 'email', headerName: 'Email', width: 300 },
        {
            field: 'role',
            headerName: 'Role',
            width: 200,
        },
        {
            field: 'group',
            headerName: 'Store',
            width: 200,
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 200,
            renderCell: (params) => {
                return (
                    <Link to={"/users/" + params.row._id}>
                        <button className="userListEdit">Edit</button>
                    </Link>
                );
            },
        },
    ];
    return (
        <div className="userList">
            <div className="userListTitleContainer">
                <h1 className="userTitle">User List</h1>
                <Link to="/newUser">
                    <button className="userAddButton">Create</button>
                </Link>
            </div>

            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={12}
                rowsPerPageOptions={[12]}
                checkboxSelection
                autoHeight
                disableSelectionOnClick
                getRowId={(row) => row._id}
            />
        </div>
    );
}
