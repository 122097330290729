import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import StorefrontIcon from '@mui/icons-material/Storefront';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import api from '../../api';
import './user.css';

export default function User() {
    const [user, setUser] = useState({
        name: '',
        email: '',
        role: '',
        group: []
    });

    const { userId } = useParams();
    useEffect(() => {
        api.getUserById(userId).then(res => {
            setUser(res.data);
        });
    }, [userId]);

    const handleNameChange = (e) => {
        setUser({ ...user, name: e.target.value });
    };
    const handleRoleChange = (e) => {
        setUser({ ...user, role: e.target.value });
    };
    const handleGroupChange = (e) => {
        let group = [];
        if (e.target.checked) {
            group = user.group.concat([e.target.value]);
        } else {
            group = user.group.filter(el => el !== e.target.value);
        }
        setUser({ ...user, group: group });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await api.updateUserById(userId, user);
        if (res.status === 'fail') {
            alert(res);
        } else {
            alert('User updated');
        };
    };

    return (
        <div className="user">
            <div className="userTitleContainer">
                <h1 className="userTitle">Edit User</h1>
            </div>
            <div className="userContainer">
                <div className="userShow">
                    <div className="userShowTop">
                        <img src="https://t3.ftcdn.net/jpg/01/36/49/90/360_F_136499077_xp7bSQB4Dx13ktQp0OYJ5ricWXhiFtD2.jpg" alt="" className="userShowImg" />
                        <div className="userShowTopTitle">
                            <span className="userShowUserName">{user.name && user.name.split(' ')[0]}</span>
                        </div>
                    </div>
                    <div className="userShowBottom">
                        <span className="userShowTitle">Account Details</span>
                        <div className="userShowInfo">
                            <PermIdentityIcon className="userShowIcon" />
                            <span className="userShowInfoTitle">{user.name}</span>
                        </div>
                        <div className="userShowInfo">
                            <MailOutlineIcon className="userShowIcon" />
                            <span className="userShowInfoTitle">{user.email}</span>
                        </div>
                        <span className="userShowTitle">Permission</span>
                        <div className="userShowInfo">
                            <PeopleOutlineIcon className="userShowIcon" />
                            <span className="userShowInfoTitle">{user.role}</span>
                        </div>
                        <div className="userShowInfo">
                            <StorefrontIcon className="userShowIcon" />
                            <span className="userShowInfoTitle">{user.group}</span>
                        </div>
                        <span className="userShowTitle">Status</span>
                        <div className="userShowInfo">
                            <CheckCircleOutlineIcon className="userShowIcon" />
                            <span className="userShowInfoTitle">{user.active === false ? 'Deactive' : 'Active'}</span>
                        </div>
                    </div>
                </div>
                <div className="userUpdate">
                    <span className="userUpdateTitle">Edit</span>
                    <form className="userUpdateForm" onSubmit={handleSubmit}>
                        <div className="userUpdateLeft">
                            <div className="userUpdateItem">
                                <label >Full Name</label>
                                <input type="text" className="userUpdateInput" value={user.name} onChange={handleNameChange} />
                            </div>
                            <div className="userUpdateItem">
                                <label >Email</label>
                                <input type="text" className="userUpdateInput" value={user.email} disabled />
                            </div>
                            <div className="userUpdateItem">
                                <label >Role</label>
                                <div className="userUpdateRole" onChange={handleRoleChange}>
                                    <input type="radio" name="role" id="staff" value="staff" checked={user.role.includes('staff')} readOnly />
                                    <label htmlFor="staff">Staff</label>
                                    <input type="radio" name="role" id="manager" value="manager" checked={user.role.includes('manager')} readOnly />
                                    <label htmlFor="manager">Manager</label>
                                    <input type="radio" name="role" id="admin" value="admin" checked={user.role.includes('admin')} readOnly />
                                    <label htmlFor="admin">Administrator</label>
                                </div>
                            </div>
                            <div className="userUpdateItem">
                                <label >Store</label>
                                <div className="userUpdateGroup" onChange={handleGroupChange}>
                                    <label>
                                        <input type="checkbox" name="group" value="Surrey" checked={user.group.includes('Surrey')} readOnly />
                                        Surrey
                                    </label>
                                    <label>
                                        <input type="checkbox" name="group" value="Vancouver" checked={user.group.includes('Vancouver')} readOnly />
                                        Vancouver
                                    </label>
                                    <label>
                                        <input type="checkbox" name="group" value="Coquitlam" checked={user.group.includes('Coquitlam')} readOnly />
                                        Coquitlam
                                    </label>
                                    <label>
                                        <input type="checkbox" name="group" value="Tsawwassen" checked={user.group.includes('Tsawwassen')} readOnly />
                                        Tsawwassen
                                    </label>
                                    <label>
                                        <input type="checkbox" name="group" value="SixteenthAve" checked={user.group.includes('SixteenthAve')} readOnly />
                                        SixteenthAve
                                    </label>
                                    <label>
                                        <input type="checkbox" name="group" value="Aspen" checked={user.group.includes('Aspen')} readOnly />
                                        Aspen
                                    </label>
                                    <label>
                                        <input type="checkbox" name="group" value="Macleod" checked={user.group.includes('Macleod')} readOnly />
                                        Macleod
                                    </label>
                                    <label>
                                        <input type="checkbox" name="group" value="Sunridge" checked={user.group.includes('Sunridge')} readOnly />
                                        Sunridge
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="userUpdateRight">
                            <button className="userUpdateButton">Update</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
