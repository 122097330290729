import { useState } from 'react';
import api from '../../api';
import './newUser.css';

export default function NewUser() {
    const [user, setUser] = useState({
        name: '',
        email: '',
        password: '',
        passwordConfirm: '',
        role: 'staff',
        group: []
    });

    const handleNameChange = (e) => {
        setUser({ ...user, name: e.target.value });
    };
    const handleEmailChange = (e) => {
        setUser({ ...user, email: e.target.value });
    };
    const handlePasswordChange = (e) => {
        setUser({ ...user, password: e.target.value });
    };
    const handlePasswordConfirmChange = (e) => {
        setUser({ ...user, passwordConfirm: e.target.value });
    };
    const handleRoleChange = (e) => {
        setUser({ ...user, role: e.target.value });
    };
    const handleGroupChange = (e) => {
        let group = [];
        if (e.target.checked) {
            group = user.group.concat([e.target.value]);
        } else {
            group = user.group.filter(el => el !== e.target.value);
        }
        setUser({ ...user, group: group });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await api.createUser(user);
        if (res.status === 'error') {
            alert(res);
        } else {
            alert('User created');
        };
    };

    return (
        <div className="newUser">
            <h1 className="newUserTitle">New User</h1>
            <form className="newUserForm" onSubmit={handleSubmit}>
                <div className="newUserItem">
                    <label >Full Name *</label>
                    <input type="text" placeholder="" value={user.name} onChange={handleNameChange} />
                </div>
                <div className="newUserItem">
                    <label >Email *</label>
                    <input type="text" placeholder="" value={user.email} onChange={handleEmailChange} />
                </div>
                <div className="newUserItem">
                    <label >Password *</label>
                    <input type="password" placeholder="" value={user.password} onChange={handlePasswordChange} />
                </div>
                <div className="newUserItem">
                    <label >Password Confirm *</label>
                    <input type="password" placeholder="" value={user.passwordConfirm} onChange={handlePasswordConfirmChange} />
                </div>
                <div className="newUserItem">
                    <label >Role</label>
                    <div className="newUserRole" onChange={handleRoleChange}>
                        <input type="radio" name="role" id="staff" value="staff" defaultChecked />
                        <label htmlFor="staff">Staff</label>
                        <input type="radio" name="role" id="manager" value="manager" />
                        <label htmlFor="manager">Manager</label>
                        <input type="radio" name="role" id="admin" value="admin" />
                        <label htmlFor="admin">Administrator</label>
                    </div>
                </div>
                <div className="newUserItem">
                    <label >Store</label>
                    <div className="newUserGroup" onChange={handleGroupChange}>
                        <label>
                            <input type="checkbox" name="group" value="Surrey" checked={user.group.includes('Surrey')} readOnly />
                            Surrey
                        </label>
                        <label>
                            <input type="checkbox" name="group" value="Vancouver" checked={user.group.includes('Vancouver')} readOnly />
                            Vancouver
                        </label>
                        <label>
                            <input type="checkbox" name="group" value="Coquitlam" checked={user.group.includes('Coquitlam')} readOnly />
                            Coquitlam
                        </label>
                        <label>
                            <input type="checkbox" name="group" value="Tsawwassen" checked={user.group.includes('Tsawwassen')} readOnly />
                            Tsawwassen
                        </label>
                        <label>
                            <input type="checkbox" name="group" value="SixteenthAve" checked={user.group.includes('SixteenthAve')} readOnly />
                            SixteenthAve
                        </label>
                        <label>
                            <input type="checkbox" name="group" value="Aspen" checked={user.group.includes('Aspen')} readOnly />
                            Aspen
                        </label>
                        <label>
                            <input type="checkbox" name="group" value="Macleod" checked={user.group.includes('Macleod')} readOnly />
                            Macleod
                        </label>
                        <label>
                            <input type="checkbox" name="group" value="Sunridge" checked={user.group.includes('Sunridge')} readOnly />
                            Sunridge
                        </label>
                    </div>
                </div>

                <div className="newUserItem">
                    <button className="newUserButton">Create</button>
                </div>
            </form>
        </div>
    );
}
