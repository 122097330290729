import React from 'react';
import './widgetLg.css';

export default function WidgetLg() {
    return (
        <div className="widgetLg">
            <h3 className="widgetLgTitle">Latest actions</h3>
        </div>
    );
}
