import React from 'react';
import WidgetLg from '../../components/widgetLg/WidgetLg';
import WidgetSm from '../../components/widgetSm/WidgetSm';
import './home.css';

export default function Home() {
    return (
        <div className="home">
            <div className="homeWidget">
                <WidgetSm />
                <WidgetLg />
            </div>
        </div>
    );
}
