// GET
async function getData(url = '') {
    const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response.json();
}

// POST
async function postData(url = '', data) {
    const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    return response.json();
}

// PATCH
async function patchData(url = '', data) {
    const response = await fetch(url, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    return response.json();
}

// Back to login
const goHome = (error) => {
    console.log(error);
    alert('You may have logged out from openbox.ca, please login again.');
    sessionStorage.clear();
    window.location.href = 'https://shoparse.com/';
};

const login = async (data) => {
    const res = await postData('/api/v1/users/login', data);
    return res;
};

const getUsers = async () => {
    try {
        const res = await getData('/api/v1/users');
        res.data.forEach(el => el.avatar = 'https://t3.ftcdn.net/jpg/01/36/49/90/360_F_136499077_xp7bSQB4Dx13ktQp0OYJ5ricWXhiFtD2.jpg');
        return res;
    } catch (error) {
        goHome(error);
    }
};

const getUserById = async (userId) => {
    try {
        const res = await getData(`/api/v1/users/${userId}`);
        return res;
    } catch (error) {
        goHome(error);
    }
};

const updateUserById = async (userId, data) => {
    try {
        const res = await patchData(`/api/v1/users/updateUser/${userId}`, data);
        return res;
    } catch (error) {
        goHome(error);
    }
};

const createUser = async (data) => {
    try {
        const res = await postData('/api/v1/users/signup', data);
        return res;
    } catch (error) {
        goHome(error);
    }
};

const api = { login, getUsers, getUserById, updateUserById, createUser };
export default api;