import React from 'react';
import { Link } from 'react-router-dom';
import LineStyleIcon from '@mui/icons-material/LineStyle';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import TimelineIcon from '@mui/icons-material/Timeline';
import './sidebar.css';

export default function Sidebar() {
    return (
        <div className="sidebar">
            <div className="sidebarWrapper">
                <div className="sidebarMenu">
                    <h3 className="sidebarTitle">Dashboard</h3>
                    <ul className="sidebarList">
                        <Link to="/" className="link">
                            <li className="sidebarListItem active">
                                <LineStyleIcon className="sidebarIcon" /> Home
                            </li>
                        </Link>
                        <Link to="/users" className="link">
                            <li className="sidebarListItem">
                                <PersonOutlineIcon className="sidebarIcon" /> Users
                            </li>
                        </Link>
                        {/* <li className="sidebarListItem">
                            <TimelineIcon className="sidebarIcon" /> Time Line
                        </li> */}
                    </ul>
                </div>
            </div>
        </div >
    );
}
