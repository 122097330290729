import { useState } from "react";

const useAuth = () => {
    const getAuth = () => {
        const auth = sessionStorage.getItem('auth');
        return auth;
    };

    const [auth, setAuth] = useState(getAuth());

    const saveAuth = (auth) => {
        sessionStorage.setItem('auth', auth);
        setAuth(auth);
    };

    return { auth, setAuth: saveAuth };
};

export default useAuth;